<template>
    <v-container style="max-width:100vw;">
        <!-- Filtros >
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterPayrolls @filtersItem="filtersItem"/>
        </v-navigation-drawer-->
        <!-- Contenedor -->
        <v-data-table show-expand :headers="headers" :items="items" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Productos | Servicios </v-toolbar-title>
                    <!--v-btn icon>
                        <v-icon @click="openFilter()">mdi-filter</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn-->
                </v-toolbar>
            </template>




            <template v-slot:[`item.gallery`]="{ item }">
                <v-icon v-if="item.gallery!=undefined" @click="viewGallery(item.images)" small>mdi-image-multiple</v-icon>
                <v-dialog v-model="dialogGallery" width="900">
                    <v-card>
                        <v-row justify="space-around" class="ma-0">
                            <v-col v-for="(image, n) in imagesGallery" :key="n" class="d-flex child-flex pa-0" cols="3" style="border: solid 1px #b7b7b7; border-collapse: collapse;">
                                <v-img :src="'https://intenbackend.unocrm.mx/files/items/'+image"
                                    aspect-ratio="1" class="grey lighten-2">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    <a style="color:transparent!important; margin-left:10px;" :href="'https://intenbackend.unocrm.mx/files/items/'+image" :download="image" target="_blank"><v-icon>mdi-eye</v-icon></a>
                                </v-img>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Cerrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>




            <!-- Detalle PC -->
            <template v-slot:expanded-item="{ headers, item }">
                <td style="padding:0px!important;" :colspan="headers.length" v-if="item.product_type=='Variable'">
                    <v-data-table :headers="headers2" :items="variations(item.id)" class="elevation-0 pa-5" style="background: #f7f7f7;!important" dense>
                        <template slot="no-data">
                            No existen productos registrados.
                        </template>
                        <template v-slot:[`item.categories`]="{ item }">
                            <template v-for="(categori, index) in item.categories">
                                <span v-bind:key="index">{{category(categori)}}, </span>
                            </template>
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                            {{money(item.price)}}
                        </template>
                        <template v-slot:[`item.cost`]="{ item }">
                            {{money(item.cost)}}
                        </template>
                        <template v-slot:[`item.is_published`]="{ item }">
                            <v-icon v-if="item.is_published==true" color="green">mdi-record</v-icon>
                            <v-icon v-else color="red">mdi-record</v-icon>
                        </template>
                        <template v-slot:[`item.unit_id`]="{ item }">
                            {{unit(item.unit_id)}}
                        </template>
                        <template v-slot:[`item.provider_id`]="{ item }">
                            {{provider(item.provider_id)}}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
                            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </td>
            </template>
            <template slot="no-data">
                No existen productos registrados.
            </template>
            <template v-slot:[`item.categories`]="{ item }">
                <template v-for="(categori, index) in item.categories">
                    <span v-bind:key="index">{{category(categori)}}, </span>
                </template>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                {{money(item.price)}}
            </template>
            <template v-slot:[`item.cost`]="{ item }">
                {{money(item.cost)}}
            </template>
            <!--template v-slot:[`item.weight`]="{ item }">
                {{item.weight}}kg
            </template-->
            <template v-slot:[`item.is_published`]="{ item }">
                <div v-if="item.product_type!='Variable'">
                    <v-icon v-if="item.is_published==true" color="green">mdi-record</v-icon>
                    <v-icon v-else color="red">mdi-record</v-icon>
                </div>
            </template>
            <template v-slot:[`item.unit_id`]="{ item }">
                {{unit(item.unit_id)}}
            </template>
            <template v-slot:[`item.provider_id`]="{ item }">
                {{provider(item.provider_id)}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="edit(item.id)">mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>
        <!-- Crear actividad -->
        <v-dialog v-model="createDialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#e74919" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <createItem @closeDialogItem="closeDialogItem"/>
        </v-dialog>
        <!-- editar -->
        <v-dialog v-model="editDialog" max-width="600px">
            <editItem @closeDialogItem="closeDialogItem" v-bind:editedItem="editedItem"/>
        </v-dialog> 
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from "axios";
import editItem from "../inventary/edit"
import createItem from "../inventary/create"
export default {
    components: {
        'editItem':editItem,
        'createItem':createItem,
    },
    data:()=>({ 
        dialogGallery:false,
        imagesGallery:[],
        snackbar: {
            show: false,
            message: null,
            color: null
        }, 
        filters: false,
        editDialog:false,
        createDialog:false,
        info:true,
        editedItem:'',
        headers:[
            {text: '', value: 'data-table-expand' },
            {text: 'Nombre', value: 'name'},
            //{text: 'Codigo', value: 'sku'},
            {text: 'Codigo Macro', value: 'macro'},
            {text: 'Fotos', value: 'gallery', sortable: false },
            {text: 'Tipo', value: 'type'},
            {text: 'Proveedor', value: 'provider_id'},
            {text: 'Unidad', value: 'unit_id'},
            {text: 'Peso', value: 'weight'},
            {text: 'Precio', value: 'price'},
            {text: 'Costo', value: 'cost'},
            {text: 'Categoría', value: 'categories'},
            {text: 'Publicado', value: 'is_published'},
            {text: 'Inventario Ideal', value: 'ideal_inventory'},
            {text: 'Inventario', value: 'inventory'},
            {text: 'Acciones', value: 'actions', sortable: false },
        ],
        headers2:[
            {text: 'Nombre', value: 'name'},
            //{text: 'Codigo', value: 'sku'},
            {text: 'Codigo Macro', value: 'macro'},
            //{text: 'Tipo', value: 'type'},
            //{text: 'Proveedor', value: 'provider_id'},
            {text: 'Unidad', value: 'unit_id'},
            //{text: 'Peso', value: 'weight'},
            {text: 'Precio', value: 'price'},
            {text: 'Costo', value: 'cost'},
            {text: 'Categoría', value: 'categories'},
            {text: 'Publicado', value: 'is_published'},
            {text: 'Inventario Ideal', value: 'ideal_inventory'},
            {text: 'Inventario', value: 'inventory'},
            {text: 'Acciones', value: 'actions', sortable: false },
        ],
        dialog:false,
    }),
    computed:{
        items:{
            get(){
                return this.$store.state.item.items.filter(item=>item.product_type != 'Variación').map(id=>{
                    return{
                        id:id.id,
                        name:id.name,
                        sku:id.sku,
                        macro:id.macro,
                        is_published:id.is_published,
                        superiorID:id.superiorID,
                        type:id.type,
                        provider_id:id.provider_id,
                        unit_id:id.unit_id,
                        cost:id.cost,//calcular despues
                        price:id.price,//calcular despues
                        product_type:id.product_type,
                        created_by_user_id:id.created_by_user_id,
                        weight:id.weight + ' kg',
                        //arrays
                        inventory:id.inventory,
                        categories:id.categories,
                        ideal_inventory:id.ideal_inventory,
                        images:id.images,

                        /*
                        weight:id.weight,
                        longitude:id.longitude,
                        heihgt:id.heihgt,
                        width:id.width,
                        discoiunt_price:id.discoiunt_price,
                        images:id.images,
                        featured:id.featured,
                        short_description:id.short_description,
                        description:id.description,
                        start_promo:id.start_promo,
                        end_promo:id.end_promo,
                        tax:id.tax,
                        tax_type:id.tax_type,
                        buy_when_available:id.buy_when_available,
                        */
                    }
                });
            }
        },
    },
    methods:{
        filtersItem: function(params) {
            
        },
        money(amount){
            if(amount!=undefined){
                return (amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
            }else{
                return '-'
            }
        },
        variations(item_id){
            var respuesta = this.$store.state.item.items.filter(item=>item.product_type == 'Variación').filter(item=>item.superiorID == item_id)
            return respuesta
        },
        provider(id){
            if(id!=undefined){
                return this.$store.state.provider.providers.filter(provider=>provider.id == id).map(provider=>provider.name)[0];
            }else{
                return '-'
            }
        },
        unit(id){
            if(id!=undefined){
                return this.$store.state.unit.units.filter(unit=>unit.id == id).map(unit=>unit.name)[0];
            }else{
                return '-'
            }
        },
        deleteItem (item) {
            let id = item.id
            if (confirm('¿Seguro que deseas borrar este producto/servicio?')) {
                axios.delete("https://intenbackend.unocrm.mx/api/v1/item/delete/"+id).then(response => {
                    this.$store.dispatch('item/getItems')
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                });
            }
        },
        viewGallery(images){
            this.imagesGallery = images
            this.dialogGallery = true
        },
        edit(id){
            this.editedItem = this.$store.state.item.items.filter(item=>item.id == id)[0]
            this.editDialog = true
        },
        closeDialogItem: function(params) {
            this.editDialog = params;
            this.createDialog = params;
            this.$store.dispatch('item/getItems')
        },
        category(id){
            return this.$store.state.category.categories.filter(category=>category.id == id).map(category => category.name)[0];
        }
    },
}
</script>