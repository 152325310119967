var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Productos | Servicios ")])],1)]},proxy:true},{key:"item.gallery",fn:function(ref){
var item = ref.item;
return [(item.gallery!=undefined)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.viewGallery(item.images)}}},[_vm._v("mdi-image-multiple")]):_vm._e(),_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.dialogGallery),callback:function ($$v) {_vm.dialogGallery=$$v},expression:"dialogGallery"}},[_c('v-card',[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"space-around"}},_vm._l((_vm.imagesGallery),function(image,n){return _c('v-col',{key:n,staticClass:"d-flex child-flex pa-0",staticStyle:{"border":"solid 1px #b7b7b7","border-collapse":"collapse"},attrs:{"cols":"3"}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":'https://intenbackend.unocrm.mx/files/items/'+image,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('a',{staticStyle:{"color":"transparent!important","margin-left":"10px"},attrs:{"href":'https://intenbackend.unocrm.mx/files/items/'+image,"download":image,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Cerrar")])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.product_type=='Variable')?_c('td',{staticStyle:{"padding":"0px!important"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 pa-5",staticStyle:{"background":"#f7f7f7"},attrs:{"headers":_vm.headers2,"items":_vm.variations(item.id),"dense":""},scopedSlots:_vm._u([{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_vm._l((item.categories),function(categori,index){return [_c('span',{key:index},[_vm._v(_vm._s(_vm.category(categori))+", ")])]})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.cost))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}},{key:"item.provider_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.provider(item.provider_id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen productos registrados. ")])],2)],1):_vm._e()]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_vm._l((item.categories),function(categori,index){return [_c('span',{key:index},[_vm._v(_vm._s(_vm.category(categori))+", ")])]})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.cost))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.product_type!='Variable')?_c('div',[(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])],1):_vm._e()]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}},{key:"item.provider_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.provider(item.provider_id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen productos registrados. ")])],2),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e74919","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('createItem',{on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editItem',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }